import { useState, useEffect, useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { onWordCloudUpdate } from "./firebase/on-wordcloud-update";
import { addWorldCloudValue } from "./firebase/add-wordcloud-word";
import { LoadingDNA } from ":src/components/loaders";
import { useParams } from "wouter";
import ReactWordcloud, { OptionsProp } from "react-wordcloud";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { global } from ":global";
import { Title } from ":src/components/title";

type Answers = { text: string; value: number }[];
let text = "";
export default function WordCloud(props: { displayOnly?: boolean }) {
  const inputRef = useRef(null) as any;
  const [words, setWords] = useState([] as Answers);
  const [question, setQuestion] = useState(undefined);
  const [worldCloud, setWordCloud] = useState(
    {} as { question: string; answers: Record<string, number> }
  );
  const [loading, setLoading] = useState(true);
  const params = useParams<{ eventId: string }>();
  const { eventId } = params;
  const options: OptionsProp = {
    scale: "linear",
    randomSeed: "1",
    rotations: 2,
    rotationAngles: [0, 0],
    fontSizes: !props.displayOnly ? [40, 100] : [60, 150], // maybe turn to reactive and take number of words into consideration
    padding: 30,
    spiral: "rectangular",
    transitionDuration: 1000,
    deterministic: true,
    colors:["#0C2838", "#8DB2AD", "#EE8C22", "#AC2336", "#7F7F7F", "#4A6360", "#E25D3B", "#6E1A47",],
    fontFamily:"Effra"
  };

  // on mounting | setup
  useEffect(() => {
    text = undefined as any;
    try {
      if (eventId && typeof eventId === "string" && eventId.length > 0) {
        onWordCloudUpdate({
          eventId: eventId as string,
          onUpdate: onWordCloudChanged,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  function onWordCloudChanged(val: any) {
    if (!val) {
      setLoading(false);
      return;
    }
    const result = [] as Answers;
    const answers = val?.answers;
    if (answers && Object.keys(answers).length > 0) {
      for (const key in answers) {
        result.push({ text: key, value: answers[key] });
      }
    }
    unstable_batchedUpdates(() => {
      setWords(result);
      setQuestion(val.question);
      setWordCloud(val);
      setLoading(false);
      if (props.displayOnly) {
        global.store.actions.qr_code_bar.setCodeSubRoute(
          `/play/wordcloud/${eventId}`
        );
      }
    });
  }

  function handleTextChange(e: any) {
    text = e.target?.value;
  }
  async function handleSubmit(e: any) {
    e.preventDefault();
    if (!text || text === "") {
      return;
    }
    let value = 1;
    if (worldCloud.answers && worldCloud.answers[text]) {
      value = worldCloud.answers[text] + 1;
    }
    await addWorldCloudValue({
      eventId: eventId as string,
      ans: text,
      val: value,
    });
    inputRef.current.value = "";
  }

  return (
    <div className="flex flex-col w-full h-full overflow-hidden px-5">
      <Title title=
        {question ?? ""} 
        play={!props.displayOnly}
        />
      
      <div className="flex flex-col w-full h-full items-center justify-center">
        {loading ? (
          <LoadingDNA width={"30%"} height={"30%"} />
        ) : !question ? (
          <span>this cloud doesn't exist!</span>
        ) : (
          <ReactWordcloud
            words={words}
            options={options as any}
            // minSize={[100, 100]}
          />
        )}
      </div>
      {!props.displayOnly && (
        <div
          className="flex flex-row w-full h-10% space-x-2 items-center justify-center mb-2"
          // onSubmit={handleSubmit}
        >
          <InputText
          className="rounded-none"
            type="text"
            ref={inputRef}
            onChange={handleTextChange}
            placeholder="Enter your answer"
          />
          <Button className="bg-[#0D2937] rounded-none border-none" label={"Submit"} onClick={handleSubmit} />
        </div>
      )}
    </div>
  );
}
